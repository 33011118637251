




































































































































































































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {toInstanceForce} from '~/utils/Serializer'
import {Comment, EditedContent, Post, subchannels} from '~/components/community/community-models'
import subjects from '~/data/subjects'
import CloseUpImage from '~/components/closeup-image.vue'
import Member from '~/views/member/member.vue'
import {Dict, userPropicURL} from '~/utils/misc'
import {Channels, ChannelName} from '@afterschool.dev/as-data-admin'

@Component({
    components: {
        Member,
        CloseUpImage
    },
    metaInfo() {
        return {
            title: 'Community Post'
        }
    }
})
export default class PostView extends Vue {
    Channels = Channels
    ChannelName = ChannelName
    subchannels = subchannels

    channel = 0
    subjectsOp: { value: number, label: string }[] = []

    post: Post = new Post()

    comments: Comment[] = []
    names: Dict<string> = {}

    closeUpURL = ''

    step: number = 20
    totalPages: number = 0
    currentPage: number = 1

    channelChanged = false
    channelUpdating = false

    // comment edit record
    commentEditRecord: EditedContent[] = []

    setCommentEditRecordPopUp(commentEditRecord: EditedContent[]) {
        this.commentEditRecord = commentEditRecord
    }

    // data query
    async toPage(pageNumber: number) {
        this.currentPage = pageNumber
        this.updateComments()
    }

    mounted() {
        const keys = Object.keys(subjects)
        keys.pop()
        this.subjectsOp = keys.map((key) => {
            return {value: Number(key) + 1000, label: subjects[key]}
        })
        createRequest('/community-v2/post/' + this.$route.params['id'], 'get').send()
            .then((res) => {
                this.post = toInstanceForce(new Post(), res.data)
                this.totalPages = Math.ceil(this.post.comment_count / this.step)
                const title = this.post.content
                this.channel = this.post.channel
                if ([0, 1].includes(this.channel)) {
                    this.channel = 1000 + this.post.subchannel
                }
                document.title = title
                this.updateComments()
            })
    }

    updateChannel() {
        const body = {
            channel: this.channel,
            subchannel: 0,
        }
        if (this.channel >= 1000) {
            body.subchannel = this.channel - 1000
        }
        this.channelUpdating = true
        createRequest('/community-v2/post/' + this.post._id + '/channel', 'patch', {}, body).send()
            .then((res) => {
                this.$message.success('Saved')
                this.post.channel = body.channel
                this.post.channel = body.subchannel
                this.channelUpdating = false
            })
    }

    findComment(commentId: string) {
        const cm = this.comments.find((comment: Comment) => {
            return comment.comment_id == commentId
        })

        if (cm != undefined) {
            return cm
        } else {
            const emptyCm = new Comment()
            emptyCm.content = '404 Not Found'
            return emptyCm
        }
    }

    findCommentOwner(mid: string) {
        return this.comments.find((comment: Comment) => {
            return comment.member_id == mid
        })
    }

    async updateComments() {
        const query = {
            // from: (this.currentPage - 1) * this.step + "",
            // number: this.step + "",
            skip: 0 + '',
            limit: 0 + '',
            blocked: 'both',
            vote: 'true'
        }
        const res = await createRequest('/community-v2/post/' + this.$route.params['id'] + '/comments', 'get', query).send()
        // this.totalPages = Math.ceil(this.post.comment_count / this.step);
        this.comments = res.data.comments.map(comment => toInstanceForce(new Comment(), comment))
        const memberIds = this.comments.reduce((all, cm) => [...all, ...cm.up_vote, ...cm.down_vote].unique(), [])

        const nRes = await createRequest('/display-names/by-id', 'post', {}, {
            member_ids: memberIds
        }).send()
        this.names = nRes.data

        const cmid = this.$route.query['cmid'] as string

        // TODO: Spaces before function parens are disallowed
        if (cmid) this.$nextTick(() => {
                this.$router.replace(`#${cmid}`)
            }
        )
    }

    // mounted() {}

    // blocking
    blockPost() {
        createRequest('/community-v2/post/' + this.post._id + '/block', 'patch').send()
            .then((res) => {
                this.$message.error('Blocked')
                this.post.blocked = true
            })
    }

    unblockPost() {
        createRequest('/community-v2/post/' + this.post._id + '/unblock', 'patch').send()
            .then((res) => {
                this.$message.success('Unblocked')
                this.post.blocked = false
            })
    }

    blockComment(commentId: string) {
        createRequest(`/community-v2/comment/${this.post._id}/${commentId}/block`, 'patch').send()
            .then((res) => {
                const comment = this.findComment(commentId)
                if (comment != undefined) {
                    comment.blocked = true
                }
                this.$message.error('Blocked')
            })
    }

    unblockComment(commentId: string) {
        createRequest(`/community-v2/comment/${this.post._id}/${commentId}/unblock`, 'patch').send()
            .then((res) => {
                const comment = this.findComment(commentId)
                if (comment != undefined) {
                    comment.blocked = false
                }
            })
    }

    selectBestAns(commentId: string) {
        const oldBest = this.post.best_answer
        createRequest(`/community-v2/${this.post._id}/${commentId}/bestanswer`, 'post').send()
            .then((res) => {
                const comment = this.findComment(commentId)
                if (comment != undefined) {
                    comment.best_answer = true
                }
                if (oldBest != '') {
                    const commentOld = this.findComment(oldBest)
                    if (commentOld != undefined) {
                        commentOld.best_answer = false
                    }
                }
            })
    }

    tableRowClassName({row}) {
        const comment: Comment = row
        if (comment.blocked) {
            return 'comment-blocked'
        }
        if (comment.deleted)
            return 'comment-deleted'
        if (comment.best_answer)
            return 'comment-best-ans'
        return ''
    }

    propicURL(memberId) {
        return userPropicURL(memberId)
    }
}
