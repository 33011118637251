import subjects from '~/data/subjects'

export interface DataObject {
    [key: string]: any
}

export class Dimension {
    height: number = 0
    width: number = 0
}

export class Image {
    dimension: Dimension = new Dimension()
    url: string = ''
}

export class Post {
    _id: string = ''

    channel: number = 99
    subchannel: number = 99
    content: string = ''
    style: number = 99
    form: string = ''
    reward: number = 0

    member_id: string = ''
    display_name: string = ''

    image: Image[] = []

    created_date: string = ''

    created: number = 0
    liked_count: number = 0
    comment_count: number = 0
    last_comment: number = 0
    best_answer: string = ''
    blocked = false
    deleted = false
    edited = false
    edit_history: EditedContent[] = []

    private_channel: number = -1 // -1 only for frontend
}

export class EditedContent {
    content: string = ''
    image: Image[] = []
    timestamp: number = 0
}

export class Comment {
    _id: string = ''

    type: string = 'text'
    comment_id: string = ''
    post_id: string = ''
    member_id: string = ''
    display_name: string = ''
    content: string = ''
    reply_target_comment_id: string = ''

    image: Image[] = []

    best_answer: boolean = false
    best_answer_seleted_time: number = 0
    created: number = 0
    vote_count: number = 0
    up_vote: string[] = []
    down_vote: string[] = []
    blocked: boolean = false
    deleted = false
    edited = false
    edit_history: EditedContent[] = []
}

export class Report {
    _id: string = ''
    report_id: string = ''
    member_id: string = ''
    post_id: string = ''
    comment_id: string = ''
    post_content: string = ''
    comment_content: string = ''
    reason: string = ''
    report_created_date: string = ''
    report_created: number = 0

    resolved_by: string = ''
    resolved_on_date: string = ''
    resolved_on: number = 0

    resolved: boolean = false
    accepted: boolean = false
}

// export const channels = {
//     '-1': '所有頻道',
//     '0': '學術台',
//     '1': '補習台',
//     '2': '升學台',
//     '3': '生活台',
//     '4': '創意台',
//     '5': '站務台',
//     '99': '其他'
// }

export const subchannels = {
    '0': subjects,
    '1': subjects,
    '2': ['本地大學', '本地專上', '海外院校', '內地院校'],
    '3': ['校園', '運動', '飲食', '閱讀', '旅行'],
    '4': ['音樂', '講故', '話劇', '畫畫', '其他興趣'],
    '5': [''],
    '-1': ['']
}

export enum CommunitySubject {
    "" = -1,
    "CHINESE" = 0,
    "ENGLISH" = 1,
    "MATHEMATICS" = 2,
    "LS" = 3,
    "CHIN LIT." = 4,
    "ENG LIT." = 5,
    "CHIN HISTORY" = 6,
    "ECON" = 7,
    "E&RS" = 8,
    "GEOG" = 9,
    "HISTORY" = 10,
    "THS" = 11,
    "BIO" = 12,
    "CHEM" = 13,
    "PHY" = 14,
    "IS" = 15,
    "BAFS" = 16,
    "DAT" = 17,
    "HMSC" = 18,
    "ICT" = 19,
    "PE" = 20,
    "T&L" = 21,
    "MUSIC" = 22,
    "VA" = 23,
    "M1" = 24,
    "M2" = 25
}
