







import {Component, Prop, Vue} from 'vue-property-decorator'
import WtsBtn from './wts-btn.vue'

@Component
export default class CloseUpImage extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    src: string

    close = false

    showClose() {this.close = true}
    hideClose() {this.close = false}
}
